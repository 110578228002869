import * as Types from '../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/*
* This was automatically generated on by @graphql-codegen.
* DO NOT MANUALLY EDIT !!!
* Please refer to https://github.com/wistia/wistia/blob/main/frontend/graphql/README.md for more information.
* Last Updated: Tue Apr 08 2025 15:12:06 GMT+0000 (Coordinated Universal Time)
*/

const defaultOptions = {} as const;
export type FlagsmithV3IdentityAndTraitsLoaderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FlagsmithV3IdentityAndTraitsLoaderQuery = { __typename?: 'Query', currentAccount?: { __typename?: 'Account', id: string, flagsmithId: string, flagsmithIdentityData: Record<string, any> } | null | undefined };


export const FlagsmithV3IdentityAndTraitsLoaderDocument = gql`
    query FlagsmithV3IdentityAndTraitsLoader {
  currentAccount {
    id
    flagsmithId
    flagsmithIdentityData
  }
}
    `;

/**
 * __useFlagsmithV3IdentityAndTraitsLoaderQuery__
 *
 * To run a query within a React component, call `useFlagsmithV3IdentityAndTraitsLoaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagsmithV3IdentityAndTraitsLoaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagsmithV3IdentityAndTraitsLoaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useFlagsmithV3IdentityAndTraitsLoaderQuery(baseOptions?: Apollo.QueryHookOptions<FlagsmithV3IdentityAndTraitsLoaderQuery, FlagsmithV3IdentityAndTraitsLoaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlagsmithV3IdentityAndTraitsLoaderQuery, FlagsmithV3IdentityAndTraitsLoaderQueryVariables>(FlagsmithV3IdentityAndTraitsLoaderDocument, options);
      }
export function useFlagsmithV3IdentityAndTraitsLoaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlagsmithV3IdentityAndTraitsLoaderQuery, FlagsmithV3IdentityAndTraitsLoaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlagsmithV3IdentityAndTraitsLoaderQuery, FlagsmithV3IdentityAndTraitsLoaderQueryVariables>(FlagsmithV3IdentityAndTraitsLoaderDocument, options);
        }
export function useFlagsmithV3IdentityAndTraitsLoaderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FlagsmithV3IdentityAndTraitsLoaderQuery, FlagsmithV3IdentityAndTraitsLoaderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FlagsmithV3IdentityAndTraitsLoaderQuery, FlagsmithV3IdentityAndTraitsLoaderQueryVariables>(FlagsmithV3IdentityAndTraitsLoaderDocument, options);
        }
export type FlagsmithV3IdentityAndTraitsLoaderQueryHookResult = ReturnType<typeof useFlagsmithV3IdentityAndTraitsLoaderQuery>;
export type FlagsmithV3IdentityAndTraitsLoaderLazyQueryHookResult = ReturnType<typeof useFlagsmithV3IdentityAndTraitsLoaderLazyQuery>;
export type FlagsmithV3IdentityAndTraitsLoaderSuspenseQueryHookResult = ReturnType<typeof useFlagsmithV3IdentityAndTraitsLoaderSuspenseQuery>;
export type FlagsmithV3IdentityAndTraitsLoaderQueryResult = Apollo.QueryResult<FlagsmithV3IdentityAndTraitsLoaderQuery, FlagsmithV3IdentityAndTraitsLoaderQueryVariables>;